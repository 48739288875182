/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@mixin desktop {
  @media (min-width: 1025px) {
    @content;
  }
}

// should be removed in future releases
// kept as legacy support
@mixin before-desktop {
  @include mobile {
    @content;
  }
}

@mixin tablet {
  @media (min-width: 768px) and (max-width: 1024px) and (-webkit-min-device-pixel-ratio: 1) {
    @content;
  }
}

@mixin tablet-portrait {
  @media (min-width: 768px) and (max-width: 1024px) and (-webkit-min-device-pixel-ratio: 1) and (orientation: portrait) {
    @content;
  }
}

@mixin tablet-landscape {
  @media (min-width: 768px) and (max-width: 1024px) and (-webkit-min-device-pixel-ratio: 1) and (orientation: landscape) {
    @content;
  }
}

// should be removed in future releases
// kept as legacy support
@mixin after-mobile {
  @include desktop {
    @content;
  }
}

@mixin mobile {
  @media (max-width: 1024px) {
    @content;
  }
}

@mixin phone {
  @media (max-width: 767.99px) {
    @content;
  }
}

// NOTE(Bjarne): added this
@mixin after-phone {
  @include tablet {
    @content;
  }
  @include desktop {
    @content;
  }
}

@mixin standalone {
  /* stylelint-disable-next-line media-feature-name-no-unknown */
  @media all and (display-mode: standalone) {
    @content;
  }
}
