// Remember to add new variables to _root.scss for global variable access.
// Filter color generator: https://codepen.io/sosuke/pen/Pjoqqp

// Berry & Bean Color Scheme
$theme-color-1: #394831;
$theme-color-1-filter: invert(24%) sepia(3%) saturate(3567%) hue-rotate(55deg) brightness(99%) contrast(92%);
$theme-color-2: #8E8956;
$theme-color-3: #433E36;
$theme-color-4: #AE6A4F;
$theme-color-5: #C39F7C;
$theme-color-6: #E2D9C5;
$theme-color-7: #F2EEE8;
$theme-color-8: #D9D9DA;
$theme-color-9: #868686;
$theme-color-10: #000000;
$theme-color-10a: #575757;
$theme-color-10b: #868686;
$theme-color-10c: #A7A7A7;
$theme-color-10d: #D0D0D0;
$theme-color-10e: #E1E1E1;
$theme-color-11: #FFFFFF;
$theme-color-12: #7D262E;

$theme-color-copyright: #001e42;

$theme-color-badge-1: #B24310;
$theme-color-badge-2: #36462D;
$theme-color-badge-3: #A59380;
$theme-color-badge-4: #FFFFFF;
$theme-color-badge-5: #E20614;

$theme-color-product-1: #C24A18;
$theme-color-product-2: #747474;

$theme-color-overlay: rgba(5, 10, 0, 0.47);