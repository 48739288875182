/* Eksempel på 1 font-face fra Nicolai - Høks */

//  @font-face {
//    font-family: 'Haeock'; /* Navn på fonten */
//    font-style: normal; /* Normal eller italic */
//    font-weight: 300; /* Bestemmer tykkelse i hele hundrede mellem 100 & 900 */
//    font-display: swap; /* Måden fonten loades på - skal stå til swap ift. Pagespeed */
//    src: local('Haeock Light'), /* Tjekker om fonten eksisterer på computeren (hurtigste load) (1. prioritet) */
//        url('/style/fonts/Haeock-Light.woff2') format('woff2'), /* Mest optimerede fonttype til meget nye browsere (2. prioritet) */
//        url('/style/fonts/Haeock-Light.woff') format('woff'), /* Anden mest optimerede fonttype til nyere browsere (3. prioritet) */
//        url('/style/fonts/Haeock-Light.otf') format('opentype'), /* Standard gammeldags fontformat ikke optimere til web (4. prioritet) */
//        url('/style/fonts/Haeock-Light.ttf') format('truetype'); /* Standard gammeldags fontformat ikke optimere til web (5. prioritet) */
//  }

 @font-face {
   font-family: 'Degular'; /* Navn på fonten */
   font-style: normal; /* Normal eller italic */
   font-weight: 500; /* Bestemmer tykkelse i hele hundrede mellem 100 & 900 */
   font-display: swap; /* Måden fonten loades på - skal stå til swap ift. Pagespeed */
   src: local('Degular Medium'), /* Tjekker om fonten eksisterer på computeren (hurtigste load) (1. prioritet) */
       url('/style/fonts/Degular-Medium.woff2') format('woff2'), /* Mest optimerede fonttype til meget nye browsere (2. prioritet) */
       url('/style/fonts/Degular-Medium.woff') format('woff'), /* Anden mest optimerede fonttype til nyere browsere (3. prioritet) */
       url('/style/fonts/Degular-Medium.otf') format('opentype'), /* Standard gammeldags fontformat ikke optimere til web (4. prioritet) */
 }

 @font-face {
    font-family: 'Degular'; /* Navn på fonten */
    font-style: normal; /* Normal eller italic */
    font-weight: 600; /* Bestemmer tykkelse i hele hundrede mellem 100 & 900 */
    font-display: swap; /* Måden fonten loades på - skal stå til swap ift. Pagespeed */
    src: local('Degular Semibold'), /* Tjekker om fonten eksisterer på computeren (hurtigste load) (1. prioritet) */
        url('/style/fonts/Degular-Semibold.woff2') format('woff2'), /* Mest optimerede fonttype til meget nye browsere (2. prioritet) */
        url('/style/fonts/Degular-Semibold.woff') format('woff'), /* Anden mest optimerede fonttype til nyere browsere (3. prioritet) */
        url('/style/fonts/Degular-Semibold.otf') format('opentype'), /* Standard gammeldags fontformat ikke optimere til web (4. prioritet) */
  }

  @font-face {
    font-family: 'Degular'; /* Navn på fonten */
    font-style: normal; /* Normal eller italic */
    font-weight: 700; /* Bestemmer tykkelse i hele hundrede mellem 100 & 900 */
    font-display: swap; /* Måden fonten loades på - skal stå til swap ift. Pagespeed */
    src: local('Degular Bold'), /* Tjekker om fonten eksisterer på computeren (hurtigste load) (1. prioritet) */
        url('/style/fonts/Degular-Bold.woff2') format('woff2'), /* Mest optimerede fonttype til meget nye browsere (2. prioritet) */
        url('/style/fonts/Degular-Bold.woff') format('woff'), /* Anden mest optimerede fonttype til nyere browsere (3. prioritet) */
        url('/style/fonts/Degular-Bold.otf') format('opentype'), /* Standard gammeldags fontformat ikke optimere til web (4. prioritet) */
  }

  @font-face {
    font-family: 'Domaine Text'; /* Navn på fonten */
    font-style: normal; /* Normal eller italic */
    font-weight: 400; /* Bestemmer tykkelse i hele hundrede mellem 100 & 900 */
    font-display: swap; /* Måden fonten loades på - skal stå til swap ift. Pagespeed */
    src: local('Domaine Text Regular'), /* Tjekker om fonten eksisterer på computeren (hurtigste load) (1. prioritet) */
        url('/style/fonts/domaine-text-regular.woff2') format('woff2'), /* Mest optimerede fonttype til meget nye browsere (2. prioritet) */
  }
  

  @font-face {
    font-family: 'Nothing You Could Do'; /* Navn på fonten */
    font-style: normal; /* Normal eller italic */
    font-weight: 400; /* Bestemmer tykkelse i hele hundrede mellem 100 & 900 */
    font-display: swap; /* Måden fonten loades på - skal stå til swap ift. Pagespeed */
    src: local('Nothing You Could Do'),
        /* Tjekker om fonten eksisterer på computeren (hurtigste load) (1. prioritet) */
        url('/style/fonts/NothingYouCouldDo-Regular.woff2') format('woff2'),
        /* Mest optimerede fonttype til meget nye browsere (2. prioritet) */
        url('/style/fonts/NothingYouCouldDo-Regular.woff') format('woff'),
        /* Anden mest optimerede fonttype til nyere browsere (3. prioritet) */
        url('/style/fonts/NothingYouCouldDo-Regular.otf') format('opentype'),
        /* Standard gammeldags fontformat ikke optimere til web (4. prioritet) */
  }
  